import { useEffect, useRef, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Close, Download } from '../icons/icons';
import axios from 'axios'
import { Loader } from './loader';
import { BrowserDetail } from '../shared/utility';

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
pdfjs.GlobalWorkerOptions.workerSrc = url

export default function InvoicePreviewer(props: any) {

    const [numPages, setNumPages] = useState(0);
    const [theme, setTheme] = useState('posbill');
    const [pageNumber, setPageNumber] = useState(1);
    // const [showPageControls, setShowPageControls] = useState(false);
    const [fileUrl, setFileUrl] = useState('');
    const [showPreview, setShowPreview] = useState(false);
    const [showPreviewWrapper, setShowPreviewWrapper] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({ isError: false, message: '' })
    // var expression = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;

    let downloadRef: any = useRef(null);

    useEffect(() => {
        if (props.match.params.hasOwnProperty('platform')) {
            setTheme(props.match.params.platform.toLowerCase())
        }
    }, [props.match.params])

    useEffect(() => {
        getURL(props.match.params.id);
    }, [props.match.params.id])

    const getURL = (id: string) => {
        setLoading(true);
        axios.post(`${process.env.REACT_APP_BASE_URL}/receipt/find`, { id })
            .then(res => {
                setLoading(false);
                if (res.data.isError) {
                    setError({ isError: true, message: 'Receipt not available yet.' })
                } else {
                    setFileUrl(res.data.data.url);
                    setError({ isError: false, message: '' })
                }
            })
            .catch(err => {
                setLoading(false);
                console.log("Error : ", err);
            })
    }

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
    }

    const handlePreview = () => {
        setNumPages(0);
        if (!!fileUrl) {
            if (showPreviewWrapper) {
                setShowPreview(false);
                setTimeout(() => {
                    setShowPreviewWrapper(false);
                }, 300);
            } else {
                setShowPreviewWrapper(true)
                setTimeout(() => {
                    setShowPreview(true)
                }, 100);
            }
        } else {
            setError({ isError: true, message: "File not found." })
        }
    }

    const handleDownload = () => {
        downloadRef.current.click()
    }
    const isDisabled = !fileUrl || error.isError;

    const isPreviewDisable = BrowserDetail().browserName === 'Safari';

    // const showPages = !error.isError && numPages > 0 && showPageControls;
    const showPages = !error.isError && numPages > 0;

    let pageProps: { height?: number, width?: number } = window.innerWidth > window.innerHeight ? { height: window.innerHeight } : { width: window.innerWidth };
    // let pageControllerPosition = window.innerWidth > window.innerHeight ? 'landscape' : (BrowserDetail().browserName === 'Safari' && BrowserDetail().majorVersion >= 15 ? 'portrait' : 'landscape');
    return (
        <div className="App">
            <header className="App-header">
                <div className="logo">Deine digitale Rechnung</div>
                {loading
                    ? <Loader />
                    : <>
                        {error.isError
                            ? (
                                <div className="error">{error.message}</div>
                            ) : (
                                <div className="input-group">
                                    {/* <div className="label">URL</div> */}
                                    {/* <div className="url">{fileUrl}</div> */}
                                    {/* <input type="url" name="url" id="url"
                                        placeholder="https://example.com"
                                        value={fileUrl}
                                        onChange={(e) => {
                                            setShowPreview(false);
                                            setFileUrl(e.target.value)
                                        }}
                                        onKeyDown={(e) => { if (e.keyCode === 13) setShowPreview(true) }}
                                        pattern="https://.*" size={30}
                                        required /> */}
                                </div>
                            )}
                        <div className="btn-group">
                            <button onClick={() => !isPreviewDisable && handlePreview()} disabled={isDisabled || isPreviewDisable} className={`preview-btn ${(isDisabled || isPreviewDisable) ? 'disabled-btn' : ''}`}>Anzeigen</button>
                            <a href={fileUrl} download ref={downloadRef} hidden>Herunterladen</a>
                            <button disabled={isDisabled} className={`download-btn ${isDisabled ? 'disabled-btn' : ''}`} onClick={handleDownload}>Herunterladen</button>
                        </div>
                    </>}
            </header>
            {showPreviewWrapper && <div className={`body ${showPreview ? 'preview-invoice' : ''}`}>
                {!error.isError && <div className="download-button" onClick={handleDownload}><Download /></div>}
                <div className="close-button" onClick={handlePreview}><Close /></div>
                <div className="document-wrapper">
                    {/* <div dangerouslySetInnerHTML={{ __html: `<iframe src='${fileUrl}' height="100%" width="100%"/>` }} />; */}
                    <Document
                        file={fileUrl}
                        error={<div className="error">PDF konnte nicht geladen werden.</div>}
                        loading={<Loader />}
                        onLoadError={(e) => setError({ isError: true, message: e.message })}
                        onLoadSuccess={onDocumentLoadSuccess}
                        renderMode={"svg"}
                    >
                        <TransformWrapper>
                            <TransformComponent>
                                <Page pageNumber={pageNumber} {...pageProps} />
                            </TransformComponent>
                        </TransformWrapper>
                    </Document>
                    {
                        showPages && (<div id="page-controls" className="page-controller">
                            {numPages > 1 && <button disabled={numPages === 1 || pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}>&lt;</button>}
                            <p>{pageNumber} &#47; {numPages}</p>
                            {numPages > 1 && <button disabled={numPages === 1 || pageNumber === numPages} onClick={() => setPageNumber(pageNumber + 1)}>&gt;</button>}
                        </div>)
                    }
                </div>
            </div>}
        </div >
    );
}